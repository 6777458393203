

























































































import Vue from 'vue'
import { ApiResponse, Contact, Structure, FormContact, Model } from '@/interfaces'
import { mapState } from 'vuex'

interface SendMailData {
  contacts: string[]
  customText?: string
  structure?: number | null
  chosenModel?: number | null
}

interface StructureSelect {
  id: number | null
  name: string | null
  type: string | null
}

interface Recipient {
  id: number | null
  email: string | null
}

export default Vue.extend({
  props: {
    showSendMailModal: {
      type: Boolean,
      default: false,
    },
    sendMailData: {
      type: Object as () => SendMailData,
      required: false,
      default: null,
    },
    parentType: {
      type: String,
      required: false,
      default: '',
    },
    sendDocumentType: {
      type: String,
      required: false,
      default: null,
    },
    defaultSendContact: {
      type: Object as () => Contact,
      required: false,
      default: null,
    },
    debiteurSendContact: {
      type: Object as () => Contact,
      required: false,
      default: null,
    },
    sellersEmail: {
      type: Array,
      required: false,
      default: () => [] as FormContact[],
    },
  },
  data() {
    return {
      busy: false,
      showModal: false,
      structures: [] as StructureSelect[],
      contacts: [] as FormContact[],
      recipients: [] as Recipient[] | null,
      newContact: null as FormContact | null,
      models: [] as Model[],
      chosenModel: null as number | null,
    }
  },
  computed: {
    ...mapState(['user']),
    modalTitle() {
      if (this.parentType === 'invoiceForm') {
        return 'Envoi de facture par mail'
      } else if (this.parentType === 'invoiceList') {
        return 'Envoi de facture(s) par mail'
      } else if (this.parentType === 'massSale') {
        return 'Emailing vendeur'
      } else {
        return 'Envoi de document par mail'
      }
    },
    view() {
      if (this.parentType === 'invoiceForm' || this.parentType === 'invoiceList') {
        return 'mail-invoice'
      } else {
        return 'mail-document'
      }
    },
  },
  watch: {
    user(newVal) {
      if (Array.isArray(newVal.client.structures)) {
        this.structures = []
        newVal.client.structures.forEach((structure: Structure) => {
          this.structures.push({
            id: structure.id ?? null,
            name: structure.name ?? null,
            type: structure.type ?? null,
          })
        })

        // If there's only one structure, it should be selected by default
        if (this.structures.length == 1) {
          this.sendMailData.structure = this.structures[0].id
        }
      }
    },
    showSendMailModal(newVal) {
      this.showModal = newVal
      this.recipients = []
      if (this.defaultSendContact) {
        const defaultContact = {
          label: this.formatSendContact(this.defaultSendContact),
          value: this.defaultSendContact.email ?? null,
        }
        this.addContact(defaultContact)
        if (this.defaultSendContact?.additionalEmails && this.defaultSendContact?.additionalEmails?.length > 0) {
          this.defaultSendContact?.additionalEmails?.forEach((additionalEmail: string) => {
            this.addNonExistingEmail(additionalEmail)
          })
        }
      }
      if (this.debiteurSendContact) {
        const debiteur = {
          label: this.formatSendContact(this.debiteurSendContact),
          value: this.debiteurSendContact.email ?? null,
        }
        this.addContact(debiteur)
        if (this.debiteurSendContact?.additionalEmails && this.debiteurSendContact?.additionalEmails?.length > 0) {
          this.debiteurSendContact?.additionalEmails?.forEach((additionalEmail: string) => {
            this.addNonExistingEmail(additionalEmail)
          })
        }
      }
      if (this.sellersEmail) {
        ;(this.sellersEmail as FormContact[]).forEach((contact: FormContact) => {
          this.addContact(contact)
        })
      }
      if (this.models.length === 0) {
        this.getTemplates()
      }
    },
  },
  mounted() {
    if (this.user) {
      // Update structures
      this.structures = this.user.client.structures

      // If there's only one structure, it should be selected by default
      if (this.structures.length == 1) {
        this.sendMailData.structure = this.structures[0].id
      }
    }
  },
  methods: {
    closeSendMailForm() {
      this.$emit('closeSendEmailForm')
    },
    noSubmit() {
      return false
    },
    submit() {
      this.$set(this.sendMailData, 'contacts', this.recipients)
      this.$emit('submit', this.sendMailData)
    },
    disabledForm() {
      return (
        (this.parentType !== 'invoiceList' && this.parentType !== 'massSale' && this.recipients?.length === 0) ||
        (this.parentType === 'massSale' && !this.sendMailData.structure) ||
        !this.sendMailData.chosenModel
      )
    },
    searchContacts(search: string, cb: CallableFunction) {
      if (search && search.length >= 2) {
        this.busy = true
        let results = [] as FormContact[]
        this.contacts = [] as FormContact[]
        this.$api
          .get('/contacts', {
            params: {
              search: search ?? null,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            apiResponse.data.forEach((contact: Contact) => {
              let contactName = contact.formName
              if (contact.email) {
                contactName = `${contactName} - (${contact.email})`
                this.contacts.push({ value: contact.email ?? null, label: contactName ?? null })
              }
            })
            results = search ? this.contacts.filter(this.createFilter(search)) : this.contacts
          })
          .finally(() => {
            cb(results)
            this.contacts = results
            this.busy = false
          })
      } else {
        this.contacts = []
      }
    },
    createFilter(search: string) {
      return (contact: FormContact) => {
        return contact.label?.toLowerCase()?.indexOf(search.toLowerCase()) !== -1 ?? null
      }
    },
    formatSendContact(contact: Contact) {
      let contactInfos = `${contact.formName}`
      if (contact.email) {
        contactInfos = `${contactInfos} - (${contact.email})`
      }
      return contactInfos
    },
    findEmailIndex(email: string) {
      return this.recipients?.findIndex((recipient: Recipient) => email === recipient.email) ?? -1
    },
    addContact(contact: FormContact) {
      if (contact.value && this.findEmailIndex(contact.value) === -1) {
        const recipientInfo = {
          id: contact.id,
          email: contact.value,
        } as Recipient
        this.recipients?.push(recipientInfo)
        this.closeContactSelect()
      }
    },
    deleteContact(email: string) {
      const emailIndex = this.findEmailIndex(email)
      if (emailIndex > -1) {
        this.recipients?.splice(emailIndex, 1)
      }
    },
    closeContactSelect() {
      this.newContact = null
      this.contacts = []
    },
    addNonExistingEmail(email: string) {
      if (this.contacts.length === 0) {
        const nonExistingRecipient = {
          id: null,
          email,
        } as Recipient
        this.recipients?.push(nonExistingRecipient)
        this.closeContactSelect()
      }
    },
    getTemplates() {
      this.$api.get(`/model/${this.view}/list`).then((response) => {
        const apiResponse = response.data as ApiResponse

        this.models = apiResponse.data
      })
    },
  },
})
