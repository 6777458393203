import { render, staticRenderFns } from "./EditDocumentForm.vue?vue&type=template&id=55f9fb03&scoped=true&"
import script from "./EditDocumentForm.vue?vue&type=script&lang=ts&"
export * from "./EditDocumentForm.vue?vue&type=script&lang=ts&"
import style0 from "./EditDocumentForm.vue?vue&type=style&index=0&id=55f9fb03&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55f9fb03",
  null
  
)

export default component.exports