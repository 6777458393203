



















































import Vue from 'vue'
import { mapState } from 'vuex'

interface DocumentData {
  id: number | null
  type?: string
  title?: string
  reference?: string
}

interface DocumentType {
  label?: string
  value?: string
}

export default Vue.extend({
  props: {
    showEditDocumentModal: {
      type: Boolean,
      default: false,
    },
    documentToEdit: {
      type: Object as () => DocumentData,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      showModal: false,
      documentData: {} as DocumentData | null,
      documentTypes: [] as DocumentType[],
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      if (newVal) {
        this.documentTypes = newVal.client.config.documentTypes
      }
    },
    showEditDocumentModal(newVal) {
      this.showModal = newVal
    },
    documentToEdit(newVal) {
      if (newVal) {
        this.documentData = {
          ...newVal,
        }
      }
    },
  },
  mounted() {
    if (this.user) {
      this.documentTypes = this.user.client.config.documentTypes
    }
    if (this.documentToEdit) {
      this.documentData = {
        ...this.documentToEdit,
      }
    }
  },
  methods: {
    closeEditDocumentModal() {
      this.$emit('closeEditDocumentModal')
    },
    noSubmit() {
      return false
    },
    submit() {
      this.$emit('submit', this.documentData)
    },
  },
})
